@import "../../fonts.css";

.filter {
    font-family: 'gotham-ultra', sans-serif !important;
}

.filter button {
    color: #464AFF !important;
    padding: 8px 10px !important;
    font-family: 'gotham-ultra', sans-serif !important;
}

.filter button.active,
.filter button:hover {
    color: #ffffff !important;
    background-color: #464AFF;
}

.arrow {
    color: #464AFF !important;
    padding: 8px 14px !important;
}

.arrow.active,
.arrow:hover {
    color: #ffffff !important;
    background-color: #464AFF;
}

.page-link {
    color: #464AFF !important;
    font-family: 'gotham-ultra', sans-serif !important;
    padding: 8px 14px !important;
}

.page-link.active,
.number-button:hover {
    color: #ffffff !important;
    background-color: #464AFF;
}

.card {
    max-width: 330px;
    margin: 0 0 30px;
    position: relative;
    border-radius: 20px;
}

.card.hidden {
    display: none;
}

.card:hover {
    transform: scale(1.05);
}

.card h3 {
    max-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card p {
    height: 98px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
}

.fecha-card {
    color: #A8A8A8;
    font-family: 'gotham-ultra', sans-serif !important;
}

.titulo-card {
    color: #464AFF;
    font-family: 'gotham-ultra', sans-serif !important;
}

.parrafo-card {
    color: #A8A8A8;
    font-size: 20px;
    font-family: 'gotham-ultra', sans-serif !important;
    margin: 0;
}

.btn-play {
    border-radius: 100%;
    border: 20px solid #fff;
    position: absolute;
    width: 200px;
    height: 200px;
    right: 50%;
    top: 10px;
    transform: translate(50%, 0);
}

.btn-play span {
    top: 25px;
    left: 30px;
    position: absolute;
    border-left: 100px solid #fff;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
}

@media screen and (max-width: 600px) {
    .filter span {
        display: block;
    }

    .card {
        width: 250px;
        margin-bottom: 15px;
    }

    .btn-play {
        width: 120px;
        height: 120px;
    }
    .btn-play span {
        top: 15px;
        left: 18px;
        position: absolute;
        border-left: 50px solid #fff;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
    }
}