.container {
    padding: 50px 0;
}

.container-line {
    display: flex;
}

.date {
    color: #464AFF;
    font-size: 64px;
    font-family: 'gotham-ultra', sans-serif;
    width: calc(50% - 3px);
    text-align: right;
    margin: 0;
    place-self: center;
    padding: 0 30px;
}

.date.a {
    text-align: right;
}

.date.b {
    text-align: left;
}

.line {
    background-color: #464AFF;
    width: 1px;
    height: 100%;
    margin: 0 20px;
    position: relative;
}

.point {
    background-color: #464AFF;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    border-radius: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.point2 {
    background-color: #464AFF;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    border-radius: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid #f2f4f5;
    box-sizing: content-box;
}

.cont-img {
    width: calc(50% - 1px);
}

.img-line {
    background-color: #464AFF;
    width: 100%;
    max-width: 480px;
    height: 300px;
    border-radius: 30px;
    object-fit: cover;
    object-position: top;
}

.cont-text {
    background-color: #F2F4F5;
    max-width: 480px;
    border-radius: 30px;
    box-shadow: 15px 15px 25px #A8A8A84D;
    padding: 25px 20px;
    position: relative;
}

.cont-text.a {
    top: -30px;
    left: 20%;
}

.cont-text.b {
    top: -30px;
    right: 7%;
}

.cont-text h3 {
    color: #464AFF;
    font-family: 'gotham-ultra', sans-serif;
}

.cont-text h4 {
    display: none;
    color: #E32B35;
    font-family: 'gotham-ultra', sans-serif;
}

.cont-text p {
    color: #181E20;
    font-family: 'gotham-ultra', sans-serif;
}


@media screen and (max-width: 1200px) {

    .cont-text.a {
        left: 0%;
    }

    .cont-text.b {
        right: 0%;
    }
}

@media screen and (max-width: 800px) {

    .container-line {
        display: block;
    }

    .line {
        display: none;
    }

    .cont-img {
        width: 90%;
        margin: auto;
    }

    .cont-text.a {
        left: initial;
        position: relative;
        margin: auto;
    }

    .cont-text.b {
        right: 0;
        position: relative;
        margin: auto;
    }

    .date {
        display: none;
    }

    .cont-text h4 {
        display: block;
    }


}