.slick-prev {
    left: 30px !important;
    height: 63px;
    width: 63px;
    z-index: 1000;
}

.slick-next {
    right: 30px !important;
    height: 63px;
    width: 63px;
    z-index: 1000;
}

.slick-next:before,
.slick-prev:before {
    border-radius: 100%;
    font-size: 50px;
    /*box-shadow: 1px 1px #282c34 ;*/
}

.slick-slide {
    opacity: 0.5;
    height: auto !important;
}

.slick-slide.slick-active {
    opacity: 1 !important;;
}

.img-f {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.slick-track {
    height: 100%;
}

.slick-list {
    height: 100%;
}

.card-fundacion {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 400px;
    justify-content: space-evenly;
}

.parrafo-f {
    min-height: 300px;
}

@media screen and (max-width: 768px) {
    .parrafo-f {
        min-height: 150px;
    }
}