.slick-prev {
    left: 30px !important;
    height: 63px;
    width: 63px;
    z-index: 1000;
}

.slick-next {
    right: 30px !important;
    height: 63px;
    width: 63px;
    z-index: 1000;
}

.slick-next:before,
.slick-prev:before {
    border-radius: 100%;
    font-size: 50px;
    /*box-shadow: 1px 1px #282c34 ;*/
}

.slick-slide {
    opacity: 0.5;
}

.slick-slide.slick-active {
    opacity: 1 !important;
}
.slick-track{
    height: 600px;
}
.slick-list{
    height: 600px;
}

.img-carr {
    height: 600px;
    object-fit: scale-down;
    width: 100%;
}

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 768px) {

    .slick-track{
        height: 350px;
    }
    .slick-list{
        height: 350px;
    }
    .img-carr{
        height: 350px;
    }
}
