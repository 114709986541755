.card-images {
    width: 50%;
}

.fecha {
    color: #A8A8A8;
    font-size: 25px;
    font-family: 'gotham-ultra', sans-serif;
}

.titulo {
    color: #464AFF;
    font-family: 'gotham-ultra', sans-serif;
    margin-bottom: 35px;
}

.fb-share-button {
    background: #1877f2;
    color: white;
    font-family: 'gotham-ultra', sans-serif;
    padding: 7px;
    opacity: 0.8;
}

.fb-share-button i {
    padding: 0 8px 0 0;
}
.tw-share-button {
    background: #1da1f2;
    color: white;
    font-family: 'gotham-ultra', sans-serif;
    padding: 7px;
    opacity: 0.8;
}

.tw-share-button i {
    padding: 0 8px 0 0;
}

.wh-share-button {
    background: #03c100;
    color: white;
    font-family: 'gotham-ultra', sans-serif;
    padding: 7px;
    opacity: 0.8;
}
.wh-share-button i {
    padding: 0 8px 0 0;
}
.fb-share-button:hover{
    color: white;
    opacity: 1;
}
.tw-share-button:hover{
    color: white;
    opacity: 1;
}
.wh-share-button:hover{
    color: white;
    opacity: 1;
}
.parrafo {
    color: #A8A8A8;
    font-size: 20px;
    font-family: 'gotham-ultra', sans-serif;
}

@media screen and (max-width: 600px) {
    .card-images {
        width: 100%;
    }
}