@font-face {
    font-family: 'Amalfi Coast';
    src: url('./fonts/AmalfiCoast.eot');
    src: local('Amalfi Coast'), local('AmalfiCoast'), url('./fonts/AmalfiCoast.eot?#iefix') format('embedded-opentype'), url('./fonts/AmalfiCoast.woff2') format('woff2'), url('./fonts/AmalfiCoast.woff') format('woff'), url('./fonts/AmalfiCoast.ttf') format('truetype'), url('./fonts/AmalfiCoast.svg#AmalfiCoast') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wreath Halftone';
    src: url('./fonts/Wreath-HalMed.eot');
    src: local('Wreath Halftone Medium'), local('Wreath-HalMed'), url('./fonts/Wreath-HalMed.eot?#iefix') format('embedded-opentype'), url('./fonts/Wreath-HalMed.woff2') format('woff2'), url('./fonts/Wreath-HalMed.woff') format('woff'), url('./fonts/Wreath-HalMed.ttf') format('truetype'), url('./fonts/Wreath-HalMed.svg#Wreath-HalMed') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('./fonts/GothamUltra.eot');
    src: local('Gotham Ultra'), local('GothamUltra'), url('./fonts/GothamUltra.eot?#iefix') format('embedded-opentype'), url('./fonts/GothamUltra.woff2') format('woff2'), url('./fonts/GothamUltra.woff') format('woff'), url('./fonts/GothamUltra.ttf') format('truetype'), url('./fonts/GothamUltra.svg#GothamUltra') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('./fonts/Gotham-Medium.eot');
    src: local('Gotham Medium'), local('Gotham-Medium'), url('./fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Gotham-Medium.woff2') format('woff2'), url('./fonts/Gotham-Medium.woff') format('woff'), url('./fonts/Gotham-Medium.ttf') format('truetype'), url('./fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow Ultra';
    src: url('./fonts/GothamNarrow-UltraItalic.eot');
    src: local('Gotham Narrow Ultra Italic'), local('GothamNarrow-UltraItalic'), url('./fonts/GothamNarrow-UltraItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/GothamNarrow-UltraItalic.woff2') format('woff2'), url('./fonts/GothamNarrow-UltraItalic.woff') format('woff'), url('./fonts/GothamNarrow-UltraItalic.ttf') format('truetype'), url('./fonts/GothamNarrow-UltraItalic.svg#GothamNarrow-UltraItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}