body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F2F4F5 !important;
}

@font-face {
    font-family: "Gotham-Medium";
    src: local("Gotham-Medium"), url("./fonts/Gotham-Medium.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "GothamNarrow-UltraItalic";
    src: local("GothamNarrow-UltraItalic"), url("./fonts/GothamNarrow-UltraItalic.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "GothamUltra";
    src: local("GothamUltra"), url("./fonts/GothamUltra.ttf") format("truetype");
    font-weight: bold;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/*------------ Home ------------*/

#inicio {
    background-image: url("./images/shares/share-2023-01.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.breadcrumb-item {
    text-transform: uppercase;
    color: #F2F4F5 !important;
}


/*------------ Biografía ------------*/

#biografia .header-page {
    height: 16rem;
    background-image: url("./images/headers/header-bio.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.body-page {
    background-color: #F2F4F5 !important;
}

.cuadroBio {
    padding: 20px 10px;
    background: #F2F4F5 0% 0% no-repeat padding-box;
    box-shadow: -15px 15px 25px #A8A8A84D;
    border-radius: 30px;
}

@media screen and (max-width: 600px) {
    .cuadroBio {

        padding: 10px;
        box-shadow: initial;
        background: transparent;
    }
}

.title-header,
.frase-white,
.white-p {
    color: #F2F4F5;
}

.subtitle {
    color: #464AFF;
}

.cap-foto {
    background-image: url("images/backgrounds/background-bio-photo.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 60%;
    padding: 25px 0 0;
}

.cap-foto img {
    height: 25rem;
    object-fit: contain;
    object-position: bottom;
    max-width: 80%;
    width: auto;
    position: relative;
    left: 15%;
}

@media screen and (max-width: 600px) {

    .cap-foto {
        padding: 10px 0 0;
    }

    .cap-foto img {
        height: 18rem;
        left: 0;
    }

    .mobile-cap-foto {
        padding: 0 !important;
    }
}

.frase-white {
    background-image: url("images/backgrounds/background-quote.png");
    background-repeat: no-repeat;
    background-size: cover;
}


/*--- timeline --*/

ul.timeline {
    list-style-type: none;
    position: relative;
}

ul.timeline:before {
    content: ' ';
    background: #464AFF;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
}

ul.timeline>li:before {
    content: ' ';
    background: #464AFF;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #464AFF;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}


/*----------------*/


/*------------ Videoteca ------------*/

#contacto .header-page,
#fundacion .header-page,
#mis-obras .header-page,
#prensa .header-page,
#redes-sociales .header-page,
#videoteca .header-page {
    height: 16rem;
    background-image: url("./images/headers/header-fundacion.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


/*----------- fundacion ------------*/

.card-fundacion {
    box-shadow: 0px 15px 20px #00000029;
    border: none;
    border-radius: 25px;
    background-color: transparent !important;
}