.slick-prev {
    left: 30px !important;
    height: 63px;
    width: 63px;
    z-index: 1000;
}

.slick-next {
    right: 30px !important;
    height: 63px;
    width: 63px;
    z-index: 1000;
}

.slick-next:before,
.slick-prev:before {
    border-radius: 100%;
    font-size: 50px;
    /*box-shadow: 1px 1px #282c34 ;*/
}

.slick-slide {
    opacity: 0.5;
}

.slick-slide.slick-active {
    opacity: 1 !important;
    ;
}

.fecha-card {
    color: #A8A8A8;
    font-family: 'gotham-ultra', sans-serif !important;
}

.titulo-card {
    color: #464AFF;
    font-family: 'gotham-ultra', sans-serif !important;
    max-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.parrafo-card {
    color: #A8A8A8;
    font-size: 20px;
    font-family: 'gotham-ultra', sans-serif !important;
    height: 98px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
}