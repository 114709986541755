.over {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.78);
    z-index: 10000;
    /*display: none;*/
}

.over.open {
    /*display: block;*/

}

.img-over {
    height: 80%;
    object-fit: scale-down;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}