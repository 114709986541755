
.logo {
    height: auto;
    position: absolute;
    width: 11rem;
    top: 8%;
    left: 2rem;
    z-index: 10;
}

.fondo-cabecera {
    position: absolute;
    height: 5em;
    background: linear-gradient(0deg, transparent, #0000008f);
    z-index: 5;
    position: absolute;
    top: 0;
}

.pFundation{
    font-size: 25px;
}

@media screen and (max-width: 600px) {
    .direction{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logo {
        position: absolute;
        top: 5%;
        left: 50%;
        z-index: 10;
        transform: translate(-50%,0);
    }

    .pFundation{
        font-size: 16px;
    }
}