@import "../../fonts.css";

#notfound {
    position: relative;
    height: 100vh;
    max-height: -webkit-calc(100vh - 28px);
    max-height: calc(100vh - 28px);
    background: #464AFF
}

@media(min-width:768px) {
    #notfound {
        max-height: -webkit-calc(100vh - 58px);
        max-height: calc(100vh - 58px)
    }
}

#notfound .notfound {
    padding: 3.5% 3.5% 1%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, .35);
    border-radius: 50px 0
}

.notfound {
    max-width: 920px;
    width: 90%;
    line-height: 1.4;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px
}

@media(min-width:768px) {
    .notfound {
        width: 100%
    }
}

.notfound-code {
    position: absolute;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: -1
}

.notfound-code h1 {
    color: #fff;
    font-family: gotham-ultra, Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 60px;
    margin: 0;
    position: absolute;
    left: 50%;
    top: -30%;
    transform: translate(-50%, -50%)
}

@media(min-width:768px) {
    .notfound-code h1 {
        font-size: 276px;
        top: 50%
    }
}

.notfound h2 {
    font-size: 12px;
    color: #464AFF;
    font-family: gotham-ultra, Arial, Helvetica, sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0
}

@media(min-width:768px) {
    .notfound h2 {
        font-size: 24px
    }
}

.notfound p {
    font-size: 10px;
    color: #464AFF;
    font-family: gotham-ultra, Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 15px
}

@media(min-width:768px) {
    .notfound p {
        font-size: 16px
    }
}

.notfound a {
    width: 100%;
    max-width: 200px;
    height: 30px;
    font-family: gotham-ultra, Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    background: #fff;
    display: inline-block;
    padding: 5px 28px;
    border: 2px solid transparent;
    border-radius: 18px 0 18px 0;
    color: #464AFF;
    font-weight: 400;
    transition: .2s all
}

@media(min-width:768px) {
    .notfound a {
        max-width: 240px;
        height: 57px;
        font-size: 14px;
        padding: 16px 38px
    }
}

.notfound a:hover {
    background-color: #464AFF;
    border-color: #fff;
    color: #fff
}